import { create } from "zustand";
import { axiosPrivate } from "services/axios";

const useHttpRequests = create((set) => ({
  user: null,
  isLoading: false,
  error: null,
  getUser: async () => {
    set({ isLoading: true, error: null });
    try {
      const res = await axiosPrivate.get("/auth/auth-me");
      set({ user: res.data, isLoading: false });
    } catch (error) {
      set({ error, isLoading: false });
    }
  },
  bakeries: null,
  isBakeriesLoading: false,
  bakeriesError: null,
  getBakeries: async () => {
    set({ isBakeriesLoading: true, bakeriesError: null });
    try {
      const res = await axiosPrivate.get("/bakeries");
      set({ bakeries: res.data, isBakeriesLoading: false });
    } catch (error) {
      set({ bakeriesError: error, isBakeriesLoading: false });
    }
  },

  products: null,
  productCount: null,
  isProductsLoading: false,
  productsError: null,

  getProducts: async () => {
    set({ isProductsLoading: true, productsError: null });
    try {
      const res = await axiosPrivate.get("/products");
      set({
        products: res.data,
        isProductsLoading: false,
        productCount: res.data?.length,
      });
    } catch (error) {
      set({ productsError: error, isProductsLoading: false });
    }
  },
}));

export default useHttpRequests;
