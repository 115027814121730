// import { LOGO } from "assets";
import React from "react";

const Preloader = () => (
  // <div className="loader">
  //   <img src={LOGO} alt="" />
  // </div>

  <div className="loader-wrapper">
    <div className="loader"></div>
  </div>
);

export default Preloader;
