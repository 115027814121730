import React from "react";
import { NumericFormat } from "react-number-format";

const AmountInput = (props) => {
  const {
    label,
    value,
    onChange,
    name,
    extraStyle,
    placeholder,
    error,
    helperText,
    suffix,
    style,
  } = props;
  return (
    <div
      className={`input_custom ${extraStyle}`}
      style={{
        ...style,
        marginTop: 10,
      }}
    >
      <div className="d-flex justify-content-between align-items-center mb-2">
        <label>{label}</label>
        {error && (
          <div
            style={{
              color: "red",
              textAlign: "left",
              fontSize: 15,
            }}
          >
            {helperText}
          </div>
        )}
      </div>
      <NumericFormat
        value={value}
        name={name}
        placeholder={placeholder}
        defaultValue={""}
        autoComplete="off"
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isAllowed={(values) => {
          const { floatValue } = values;
          return floatValue === undefined || floatValue <= 99999.99;
        }}
        thousandSeparator
        fixedDecimalScale={true}
        decimalScale={2}
        valueIsNumericString
        suffix={suffix || "€"}
        style={style}
      />
    </div>
  );
};

export default AmountInput;
