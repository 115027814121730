import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { useDialogStore } from "hooks";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomModal = (props) => {
  const { handleClose } = useDialogStore();
  return (
    <Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleClose()}
    >
      <div className="dialog_header">
        <h4>{props.title}</h4>
        <AiOutlineCloseCircle
          className="close_icon"
          onClick={() => {
            handleClose();
            localStorage.removeItem("new-branch");
          }}
        />
      </div>
      <DialogContent>{props.content}</DialogContent>
    </Dialog>
  );
};

export default CustomModal;
