import { create } from "zustand";

const useDialogStore = create((set) => ({
  open: false,
  setOpen: (open) => set({ open }),
  handleOpen: () => set({ open: true }),
  handleClose: () => set({ open: false }),
}));

export default useDialogStore;
