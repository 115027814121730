export const DASHBOARD = "/dashboard/:id";
export const TENANTS = "/tenants";
export const FURRAGJIU = "/furragjiu";
export const REPORTS = "/shift-reports";
export const ALL_BRANCHES = "/all-branches";
export const PRODUCTS = "/products";
export const BRANCHES = "/branches";
export const USERS = "/users";
export const LOADER = "/loader";
export const NONE = "/none";
export const SIGN_IN = "/";
