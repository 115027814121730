import { create } from "zustand";

const useSidebarStore = create((set) => ({
  toggle: localStorage.getItem("sidebarToggle") === "true" ? true : false,
  setToggle: (toggle) => {
    set({ toggle });
    localStorage.setItem("sidebarToggle", toggle);
  },
}));
export default useSidebarStore;
