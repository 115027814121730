export default function Button({
  name,
  onClick,
  className,
  style,
  padding,
  width,
  disabled,
  type,
  onKeyDown,
  loading,
}) {
  return (
    <button
      onKeyDown={onKeyDown}
      disabled={disabled || loading}
      style={{
        padding: padding,
        width: width,
        ...style,
      }}
      type={type}
      className={`main_button ${className}`}
      onClick={onClick}
    >
      {loading ? "LOADING..." : name}
    </button>
  );
}
