import React from "react";

function BgCard(props) {
  return (
    <div
      onClick={props.onClick}
      className={props.className}
      style={{
        background: "white",
        boxShadow: props.boxShadow,
        borderRadius: 10,
        width: props.width,
        flexDirection: props.flexDirection,
        height: props.height,
        padding: props.padding ? props.padding : 20,
        border: props.borderType
          ? `2px ${props.borderType} #AAA`
          : "2px solid #eee",
        position: "relative",
        cursor: props.onClick ? "pointer" : "auto",
      }}
    >
      {props.children}
    </div>
  );
}

export default BgCard;
