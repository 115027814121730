import { lazy } from "react";

export const SIGN_IN = lazy(() => import("./SignIn"));
export const LOADER = lazy(() => import("../components/Loader"));
export const TENANTS = lazy(() => import("./Tenants"));
export const USERS = lazy(() => import("./Users"));
export const DASHBOARD = lazy(() => import("./Dashboard"));
export const PRODUCTS = lazy(() => import("./Products"));
export const ALL_BRANCHES = lazy(() => import("./AllBranches"));
export const BRANCHES = lazy(() => import("./Branches"));
export const FURRAGJIU = lazy(() => import("./Furragjiu"));
export const REPORTS = lazy(() => import("./ShiftReports"));
