import React from "react";
import { FiEdit2 } from "react-icons/fi";
import { AiOutlineDelete } from "react-icons/ai";

const TableRow = (props) => {
  return (
    <div className="admin_table_data" key={props.key}>
      <div className="admin_table_cells">
        {props.children}
        {props.action && (
          <div className="admin_table_cells_actions">
            <FiEdit2 color="#777" fontSize={20} onClick={props.edit} />
            <AiOutlineDelete
              color="#ff4242"
              fontSize={26}
              onClick={props.delete}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TableRow;
