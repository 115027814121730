import React from "react";
import { Button } from "components";
import { FiSearch } from "react-icons/fi";

const AdminHeader = ({
  title = "Titile",
  subtitle = "Subtitle",
  buttonName = "Add New",
  buttonClick,
  search,
  searchBy,
  actions,
  value,
  onChange,
}) => {
  return (
    <React.Fragment>
      <div className="admin_header">
        <div className="admin_header_title">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>

        {search && (
          <div className="admin_header_center">
            <div className="admin_header_search_wrapper">
              <input
                value={value}
                onChange={onChange}
                className="admin_header_search"
                type="text"
                placeholder={`Search by ${searchBy}`}
              />
              <FiSearch className="admin_header_search_icon" />
            </div>
          </div>
        )}
        {actions && (
          <div className="admin_header_actions">
            <Button name={buttonName} onClick={buttonClick} />
          </div>
        )}
      </div>
      {search && (
        <div className="admin_header_mobile">
          <div className="admin_header_search_wrapper">
            <input
              className="admin_header_search"
              type="text"
              placeholder={`Search by ${searchBy}`}
            />
            <FiSearch className="admin_header_search_icon" />
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AdminHeader;
