import React from "react";

const IconWrap = ({ icon, onClick }) => (
  <div
    style={{ cursor: onClick ? "pointer" : "auto" }}
    onClick={onClick}
    className="icon_wrap"
  >
    {icon}
  </div>
);

export default IconWrap;
