import React from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LOGO } from "assets";
import { Tooltip } from "components";
import * as ROUTES from "constants/routes";
import { IoTrendingUpOutline } from "react-icons/io5";
import { RiUser3Line } from "react-icons/ri";
import { PiSignOutBold } from "react-icons/pi";
import { useSidebarStore } from "hooks";
import { IoIosGitBranch } from "react-icons/io";
import { BsBoxSeam } from "react-icons/bs";
import { HiOutlineClipboardDocumentCheck } from "react-icons/hi2";

const AdminSidebar = () => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  const bakery = JSON.parse(localStorage.getItem("bakery"));
  const pathname = useLocation().pathname;
  const { t } = useTranslation();
  const { toggle } = useSidebarStore();

  const sidebarLinks = [
    {
      // title: "MAIN MENU",
      children: [
        {
          id: 1,
          icon: (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M13 15.6392V18.7974"
                stroke="#EC942B"
                strokeWidth="3"
                strokeLinecap="round"
              />
              <path
                d="M22 12.6957V14.297C22 18.4036 22 20.4569 20.8284 21.7326C19.6569 23.0085 17.7712 23.0085 14 23.0085H10C6.22876 23.0085 4.34315 23.0085 3.17157 21.7326C2 20.4569 2 18.4036 2 14.297V12.6957C2 10.2866 2 9.08203 2.5192 8.08346C3.0384 7.0849 3.98695 6.46515 5.88403 5.22567L7.88403 3.91895C9.88939 2.60872 10.8921 1.95361 12 1.95361C13.1079 1.95361 14.1106 2.60872 16.116 3.91895L18.116 5.22566C20.0131 6.46515 20.9616 7.0849 21.4808 8.08346"
                stroke="#EC942B"
                strokeWidth="3"
                strokeLinecap="round"
              />
            </svg>
          ),
          link: "Dashboard",
          url: `/dashboard/${bakery?.id}`,
          role: ["ADMIN"],
        },
        {
          id: 2,
          icon: <HiOutlineClipboardDocumentCheck />,
          link: "Reports",
          url: ROUTES.REPORTS,
          role: ["ADMIN"],
        },
        {
          id: 3,
          icon: <IoTrendingUpOutline />,
          link: "Tenants",
          url: ROUTES.TENANTS,
          role: ["SUPER_ADMIN"],
        },
        {
          id: 4,
          icon: <RiUser3Line />,
          link: "Users",
          url: ROUTES.USERS,
          role: ["SUPER_ADMIN", "ADMIN"],
        },
        {
          id: 5,
          icon: <BsBoxSeam />,
          link: "Produktet",
          url: ROUTES.PRODUCTS,
          role: ["ADMIN"],
        },
        {
          id: 6,
          icon: <IoIosGitBranch />,
          link: "Deget",
          url: ROUTES.BRANCHES,
          role: ["ADMIN"],
        },
      ],
    },
  ];

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("new-branch");
    localStorage.removeItem("bakery");
    localStorage.removeItem("role");
    navigate(ROUTES.SIGN_IN);
  }

  return (
    <div className={toggle ? "admin_sidebar_none" : `admin_sidebar`}>
      <img src={LOGO} alt="" className="logo-img" />
      {sidebarLinks.map((link, i) => {
        return (
          <div className="admin_sidebar_content" key={i}>
            <p className="menu_title">{link.title}</p>
            {link.children.map((item, i) => {
              return (
                <React.Fragment>
                  {item.role.map((r) => {
                    if (r === role) {
                      return (
                        <div className="admin_sidebar_menu" key={i}>
                          <Tooltip
                            title={item.link}
                            placement="right"
                            disableHoverListener={toggle}
                          >
                            <NavLink
                              to={item.url}
                              // activeClassName="adminActiveLink"
                              className={`sidebar_link${
                                pathname === item.url ? " adminActiveLink" : ""
                              }`}
                            >
                              <div className="flexed_div">
                                {item.img && (
                                  <img
                                    src={item.img}
                                    alt=""
                                    style={{
                                      display: "block",
                                      width: 30,
                                      height: 30,
                                      borderRadius: "50%",
                                    }}
                                  />
                                )}
                                {item.icon && item.icon}
                                <span>{t(item.link)}</span>
                              </div>
                            </NavLink>
                          </Tooltip>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </React.Fragment>
              );
            })}
            <div
              className="admin_sidebar_menu logout"
              style={{ position: "absolute", bottom: 20, width: "80%" }}
            >
              <div onClick={logout} className="sidebar_link">
                <div className="flexed_div">
                  <PiSignOutBold style={{ transform: "rotate(180deg)" }} />
                  <span>Logout</span>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AdminSidebar;
