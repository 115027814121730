import React from "react";

const Select = ({
  label,
  onChange,
  extraStyle,
  error,
  helperText,
  style,
  options,
  otherOptions,
  defaultLabel,
}) => {
  return (
    <div
      className={`select_custom ${extraStyle}`}
      style={{
        ...style,
        marginTop: 10,
      }}
    >
      <div className="d-flex justify-content-between align-items-center mb-2">
        <label>{label}</label>
        {error && (
          <div
            style={{
              color: "red",
              textAlign: "left",
              fontSize: 15,
            }}
          >
            {helperText}
          </div>
        )}
      </div>
      <select onChange={onChange}>
        {otherOptions ? (
          otherOptions
        ) : (
          <React.Fragment>
            {defaultLabel && (
              <option value="">{`Select ${defaultLabel}`}</option>
            )}
            {options?.map((item, i) => (
              <option key={i} value={item.value}>
                {item.option}
              </option>
            ))}
          </React.Fragment>
        )}
      </select>
    </div>
  );
};

export default Select;
