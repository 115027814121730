import { Tooltip } from "components";
import React from "react";
import { useTranslation } from "react-i18next";
import { FaCircleInfo } from "react-icons/fa6";

const Input = ({
  label,
  value,
  onChange,
  name,
  type,
  required,
  extraStyle,
  className,
  placeholder,
  error,
  helperText,
  pattern,
  readOnly,
  onKeyDown,
  style,
  inputStyle,
  accept,
  disabled,
  multiple,
  infoIcon,
  infoIconText,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`input_custom ${extraStyle}`}
      style={{
        ...style,
        marginTop: 10,
      }}
    >
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="d-flex gap-2 align-items-center">
          {label && <label>{label}</label>}
          {infoIcon && (
            <Tooltip title={infoIconText} placement="right">
              <div>
                <FaCircleInfo
                  size={24}
                  style={{ cursor: "pointer" }}
                  color="#ec942b"
                />
              </div>
            </Tooltip>
          )}
        </div>

        {error && (
          <div
            style={{
              color: "red",
              textAlign: "left",
              fontSize: 15,
            }}
          >
            {helperText}
          </div>
        )}
      </div>
      <input
        disabled={disabled}
        style={inputStyle}
        required={required}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className={className}
        placeholder={placeholder ? placeholder : t("forms.enter_here")}
        autoComplete="off"
        pattern={pattern}
        readOnly={readOnly}
        max={type === "number" ? 99999 : "0"}
        onKeyDown={onKeyDown}
        accept={accept}
        multiple={multiple}
      />
    </div>
  );
};

export default Input;
