import { HiOutlineMenu } from "react-icons/hi";
import { PROFILE } from "assets";
import { useSidebarStore, useHttpRequests, useDialogStore } from "hooks";
import React, { useEffect } from "react";
import { Button, Modal } from "components";
import { HiOutlineArrowPathRoundedSquare } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "constants/routes";

const Navigation = () => {
  const navigate = useNavigate();
  const { toggle, setToggle } = useSidebarStore();
  const { user, getUser, isLoading, bakeries, getBakeries } = useHttpRequests();
  const { open, handleOpen, handleClose } = useDialogStore();

  useEffect(() => {
    getUser();
  }, [getUser]);

  useEffect(() => {
    getBakeries();
  }, [getBakeries]);

  let role = user?.role.replace("_", "");
  const bakery = JSON.parse(localStorage.getItem("bakery"));

  const selectBranch = (branch) => {
    navigate(`/dashboard/${branch?.id}`);
    localStorage.setItem("bakery", branch);
    handleClose();
    window.location.reload();
  };

  return (
    <React.Fragment>
      <div className="dashboard_navigation">
        <div className="d-flex align-items-center gap-4">
          <HiOutlineMenu
            onClick={() => setToggle(!toggle)}
            className="sidebar_toggle"
          />
          {role === "ADMIN" && (
            <div
              onClick={handleOpen}
              className="d-flex align-items-center gap-2"
              style={{
                background: "#ec942b",
                borderRadius: 4,
                padding: "5px 20px",
                width: "auto",
                marginTop: 10,
                cursor: "pointer",
              }}
            >
              <p style={{ fontSize: 20, color: "white" }}>{bakery?.name}</p>
              <HiOutlineArrowPathRoundedSquare color="#fff" fontSize={20} />
            </div>
          )}
        </div>

        <div className="dashboard_navigation_right">
          {/* <img src={albania} alt="" width={40} /> */}

          <div className="user">
            {isLoading ? null : (
              <div className="user_on">
                <p>{user?.firstName + " " + user?.lastName}</p>
                <span>{role}</span>
              </div>
            )}

            <img src={PROFILE} alt="" />
          </div>
        </div>
      </div>
      <Modal
        open={open}
        title={bakery?.name}
        content={
          <React.Fragment>
            <div className="d-flex align-items-center gap-4 mt-2">
              <Button
                className="secondary"
                name="See all branches"
                onClick={() => navigate(ROUTES.ALL_BRANCHES)}
              />
              <Button
                className="secondary"
                name="Add new branch"
                onClick={() => {
                  handleClose();
                  localStorage.setItem("new-branch", true);
                  navigate(ROUTES.ALL_BRANCHES);
                }}
              />
            </div>
            <div
              className="mt-4"
              style={{
                border: "1px solid #eee",
              }}
            />
            <p
              style={{
                color: "#252525",
                fontSize: 20,
              }}
              className="mt-4"
            >
              All Branches
            </p>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                gap: 20,
                marginTop: 15,
              }}
            >
              {bakeries?.length > 1 ? (
                bakeries?.map((branch, i) => {
                  return (
                    <>
                      {bakery?.id !== branch.id.toString() && (
                        <Button
                          key={i}
                          name={branch.name}
                          className="secondary"
                          onClick={() => selectBranch(branch.id)}
                        />
                      )}
                    </>
                  );
                })
              ) : (
                <p
                  style={{
                    textAlign: "center",
                    color: "#ec942b",
                    fontSize: 16,
                  }}
                >
                  No other branches!
                </p>
              )}
            </div>
          </React.Fragment>
        }
      />
    </React.Fragment>
  );
};

export default Navigation;
