import React from "react";

function TableHead({ tableHeaderData }) {
  return (
    <div className="admin_table_header">
      <div className="admin_table_header_items">
        {tableHeaderData?.map((t, i) => (
          <p key={i}>{t}</p>
        ))}
      </div>
    </div>
  );
}

export default TableHead;
