import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { AdminNavigation, AdminSidebar } from "components";
import { createBrowserHistory } from "history";
import * as PAGES from "pages";
import * as ROUTES from "constants/routes";

function App() {
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("role");
  const history = createBrowserHistory();

  return (
    <Router history={history}>
      <Routes>
        <Route path={ROUTES.SIGN_IN} element={<PAGES.SIGN_IN />} />
        <Route path={ROUTES.LOADER} element={<PAGES.LOADER />} />

        {token && (
          <>
            {role === "ADMIN" && (
              <>
                <Route
                  path={ROUTES.ALL_BRANCHES}
                  element={<PAGES.ALL_BRANCHES />}
                />
                <Route
                  path={ROUTES.DASHBOARD}
                  element={<DashboardRoute element={<PAGES.DASHBOARD />} />}
                />
                <Route
                  path={ROUTES.USERS}
                  element={<DashboardRoute element={<PAGES.USERS />} />}
                />
                <Route
                  path={ROUTES.BRANCHES}
                  element={<DashboardRoute element={<PAGES.BRANCHES />} />}
                />
                <Route
                  path={ROUTES.PRODUCTS}
                  element={<DashboardRoute element={<PAGES.PRODUCTS />} />}
                />
                <Route
                  path={ROUTES.REPORTS}
                  element={<DashboardRoute element={<PAGES.REPORTS />} />}
                />
              </>
            )}

            {role === "SUPER_ADMIN" && (
              <>
                <Route
                  path={ROUTES.TENANTS}
                  element={<DashboardRoute element={<PAGES.TENANTS />} />}
                />
                <Route
                  path={ROUTES.USERS}
                  element={<DashboardRoute element={<PAGES.USERS />} />}
                />
              </>
            )}

            <Route path={ROUTES.FURRAGJIU} element={<PAGES.FURRAGJIU />} />
          </>
        )}
        <Route path="*" element={<Navigate to={ROUTES.SIGN_IN} />} />
      </Routes>
    </Router>
  );
}

const DashboardRoute = (props) => (
  <div className="admin_dashboard">
    <AdminSidebar />
    <div className="admin_dashboard_content">
      <AdminNavigation />
      <div className="admin_dashboard_content_wrapper">{props.element}</div>
    </div>
  </div>
);

export default App;
