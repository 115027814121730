import React from "react";

const TableCell = ({ item }) => {
  return (
    <React.Fragment>
      <p style={{ margin: 0 }}>{item ? item : "-"}</p>
    </React.Fragment>
  );
};

export default TableCell;
