import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "constants/routes";

const API_URL = process.env.REACT_APP_API_KEY;

const axiosPrivate = axios.create({
  baseURL: API_URL,
  headers: { "Content-Type": "application/json" },
});

axiosPrivate.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    const navigate = useNavigate();
    console.log(error?.response?.status);
    const prevRequest = error?.config;
    if (error?.response?.status === 401 || error?.response?.status === 404) {
      prevRequest.sent = true;
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("new-branch");
      localStorage.removeItem("bakery");
      localStorage.removeItem("role");
      navigate(ROUTES.SIGN_IN);
      return axiosPrivate(prevRequest);
    } else return Promise.reject(error);
  }
);

export { axiosPrivate };
