import React, { useEffect, useRef, useState } from "react";
import { LOGO, PROFILE } from "assets";
import BgCard from "./BgCard";
import { useNavigate } from "react-router-dom";
import * as ROUTES from "constants/routes";

function Header(props) {
  const navigate = useNavigate();
  const dropdownRef = useRef(null);
  const [dropdowOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [dropdownRef]);

  function logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("new-branch");
    localStorage.removeItem("bakery");
    localStorage.removeItem("role");
    navigate(ROUTES.SIGN_IN);
  }
  return (
    <React.Fragment>
      <header className="header">
        <div className="header_left">
          <img className="logo" src={LOGO} alt="" />
        </div>
        <div
          className="header_right"
          onClick={() => setDropdownOpen(!dropdowOpen)}
        >
          <div className="profile">
            {props.isLoading ? null : (
              <div className="user">
                <p>{props.user?.firstName + " " + props.user?.lastName}</p>
                <span>{props.role}</span>
              </div>
            )}
            <img src={PROFILE} alt="" className="profile" />
          </div>
        </div>
      </header>

      {dropdowOpen && (
        <div
          ref={dropdownRef}
          style={{
            position: "absolute",
            width: 200,
            right: 15,
            top: 80,
            zIndex: 9,
          }}
        >
          <BgCard>
            <div onClick={logout} style={{ cursor: "pointer" }}>
              <p>Logout</p>
            </div>
          </BgCard>
        </div>
      )}
    </React.Fragment>
  );
}

export default Header;
